exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-js": () => import("./../../../src/pages/[...].js" /* webpackChunkName: "component---src-pages-js" */),
  "component---src-pages-nyheter-index-js": () => import("./../../../src/pages/nyheter/index.js" /* webpackChunkName: "component---src-pages-nyheter-index-js" */),
  "component---src-pages-nyheter-js": () => import("./../../../src/pages/nyheter/[...].js" /* webpackChunkName: "component---src-pages-nyheter-js" */),
  "component---src-pages-viktiga-datum-index-js": () => import("./../../../src/pages/viktiga-datum/index.js" /* webpackChunkName: "component---src-pages-viktiga-datum-index-js" */),
  "component---src-pages-viktiga-datum-js": () => import("./../../../src/pages/viktiga-datum/[...].js" /* webpackChunkName: "component---src-pages-viktiga-datum-js" */)
}

